import React, { useState, useEffect } from 'react';
import './styles.css'; // Import the CSS file
import axios from 'axios';

//   Soak  up  the sun 

function App() {
  // State for Quotes
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [buildingType, setBuildingType] = useState('single'); // 'single' or 'two'
  const [numPanels, setNumPanels] = useState(0);
  const [quote, setQuote] = useState(0);

  // State for Inquiries
  const [inquiryName, setInquiryName] = useState('');
  const [inquiryEmail, setInquiryEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmitQuote = (e) => {
    e.preventDefault();

                 // **un encrytped LAN http://**LANIP:3001**      **unencryped localhost:3001 from computer   **https://api.clearshinesolar.com for world 
    axios.post('https://api.clearshinesolar.com/quotes', { name, email, phone, address, buildingType, numPanels, quote })
      .then(response => {
        console.log(response.data);
        alert('Success! Your Interest Form has been sent. We\'ll be in touch soon!');
        setName('');
        setEmail('');
        setPhone('');
        setAddress('');
        setNumPanels(0);
        setQuote(0);
      })
      .catch(error => {
        console.error('There was an error submitting the quote!', error);
      });
  };

  const handleSubmitInquiry = (e) => {
    e.preventDefault();

    axios.post('https://api.clearshinesolar.com/inquiries', { name: inquiryName, email: inquiryEmail, subject, message })
      .then(response => {
        console.log(response.data);
        alert('Success! Your quote has been sent. We\'ll be in touch soon!');
        setInquiryName('');
        setInquiryEmail('');
        setSubject('');
        setMessage('');
      })
      .catch(error => {
        console.error('There was an error submitting the inquiry!', error);
      });
  };

  const calculateQuote = () => {
    let basePrice = buildingType === 'single' ? 55 : 60;
    let calculatedQuote = 0;

    if (numPanels < 0) {
      calculatedQuote = 'Negative panels?';
    } else if (numPanels === 0) {
      calculatedQuote = 0;
    } else {
      calculatedQuote = basePrice + (numPanels - 1);
	      // Add $10 if the number of panels is 20 or more
      if (numPanels >= 20) {
        calculatedQuote += 10;
      }
    }

    setQuote(calculatedQuote);
  };

  // Use useEffect to calculate the quote automatically
  useEffect(() => {
    calculateQuote();
  }, [buildingType, numPanels]);

  return (
    <div 
      style={{
        backgroundImage: `url('/images/background.png')`,
      }}
      className="app-container"
    >
      <header className="app-header">
        <img src="/images/solar_logo.png" alt="Clear Shine Solar Logo" className="app-logo" />
      </header>



<div className="mobile-banner">
  <img src="/images/solar_logo.png" alt="Clear Shine Solar Logo" className="mobile-logo" />
  <div className="banner-text">
    <p>Clear Shine Solar Welcomes You</p>
    <p><a href="tel:+15594295951" style={{ color: 'white', textDecoration: 'none' }}>Call Us: (559) 429-5951</a></p>
  </div>
</div>








      {/* Second Image with Text Overlay */}
      <div 
        style={{
          backgroundImage: `url('/images/navy_panel.jpeg')`,
        }}
        className="text-overlay-container"
      >
        <div className="text-overlay-content">
          <h1>Clear Shine Solar</h1>
          <p>Cleaning, Shade Removal, and More</p> 
          <p>Faithfully serving Visalia and Tulare County</p>
          <p>
            Contact us at: <br />
            <a href="mailto:clearshinesolar@gmail.com">clearshinesolar@gmail.com</a> | 
            <a href="tel:+15594295951"> 559-429-5951</a>
          </p>
        </div>
      </div>



{/* Before and After Image Section */}
<div className="before-after-section">
  <h2>Solar Panel Cleaning</h2>
  <p>Clear Shine helps your home look its best while also boosting energy production.</p>
  <div className="before-after-images">
    <div className="before-image">
      <img src="/images/before.png" alt="Before Cleaning" />
      <p>Before</p>
    </div>
    <div className="after-image">
      <img src="/images/after.png" alt="After Cleaning" />
      <p>After</p>
    </div>
  </div>
</div>







      {/* Estimator Section */}
      <div className="app-content">
        <h2>Try Our Price Estimator</h2>
        <p>See how affordable it is to keep your solar panels performing at their peak.</p>
        
        <form>
          <div>
            <label>Building Type:</label><br />
            <input
              type="radio"
              name="buildingType"
              value="single"
              checked={buildingType === 'single'}
              onChange={(e) => setBuildingType(e.target.value)}
            /> Single Story
            <br />
            <input
              type="radio"
              name="buildingType"
              value="two"
              checked={buildingType === 'two'}
              onChange={(e) => setBuildingType(e.target.value)}
            /> Two Story
          </div>

          <div>
            <label>Number of Solar Panels:</label><br />
            <input
              type="number"
              value={numPanels === 0 ? '' : numPanels}
              onChange={(e) => setNumPanels(e.target.value === '' ? 0 : Number(e.target.value))}
              required
            />
          </div>

          <div>
            <p>Estimated Price: <strong>${quote}</strong></p>
          </div>
        </form>
      </div>














      {/* Inquiry and Submission Section */}
      <div className="inquiry-section">
        <h2>Interested? Let's Get in Touch!</h2>
        <p>Fill out an interest form or Call us today to be helped by our team member.</p>
        <form onSubmit={handleSubmitQuote}>
          <div>
            <label>Name:</label><br />
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Email:</label><br />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Phone Number:</label><br />
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Address:</label><br />
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <button 
            type="submit" 
            className="submit-button"
          >
            Submit Interest Form
          </button>
        </form>
      </div>




      {/* Footer Box */}
      <div className="footer-box">
        <h3>Additional Popular Services</h3>
        <p>Shade Removal | Pressure Washing | Solar Consultation</p>
        <p>Email us at <a href="mailto:clearshinesolar@gmail.com">clearshinesolar@gmail.com</a></p>
        <p>Call us at: <a href="tel:+15594295951">(559) 429-5951</a></p>
        <p>Serving: Visalia and Tulare County</p>
        <p>&copy; 2024 ClearShine Solar. All rights reserved.</p>
	  <p>
    <a href="/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy </a>  | 
    <a href="/terms-of-service.html" target="_blank" rel="noopener noreferrer"> Terms of Service</a>
  </p>
      </div>
    </div>
  );
}

export default App;
